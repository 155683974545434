<template>
    <div class="edit-btn-wrap" v-if="carList.length > 0">
        <span v-if="!editFlag" @click="editGoods">编辑</span>
        <span v-else @click="editGoods">取消</span>
    </div>
    <div class="shop-wrap">
        <div v-for="item in carList" :key="item.id">
          <div class="shop-list" v-if="item.goodsList.length>0">
            <div class="shop-title">
                <span class="select-icon" @click="selectShopClick(item)"><img :src="item.select?require('../../images/gouxuan2.png'):require('../../images/gouxuan1.png')"/></span>
                <img class="shop-icon" src="../../images/images_company-icon.png"/>
                <span class="shopname">{{item.shopName}}</span>
                <span class="shoptype" v-if="item.dispatchType==0||item.dispatchType==9">自提</span>
                <span class="shoptype" v-if="item.dispatchType==1||item.dispatchType==9">送货上门</span>
                <!-- <img class="arrow-right" src="../../images/right_arrow.png"/> -->
            </div>
            <div class="shop-goods" v-for="itemsub in item.goodsList" :key="itemsub.id">
                <span class="select-icon" @click="selectGoodClick(itemsub)"><img :src="itemsub.select?require('../../images/gouxuan2.png'):require('../../images/gouxuan1.png')"/></span>
                <img class="goods-img" :src="itemsub.photoUrl"/>
                <div class="goods-info">
                    <h4>{{itemsub.goodsName}}</h4>
                    <div class="goods-unit">单位：{{itemsub.unit}}</div>
                    <div class="price-wrap">
                        <span class="price-num">￥{{itemsub.vipPrice?itemsub.vipPrice:itemsub.shopPrice}}</span>
                        <span class="specs">/{{itemsub.unit}}</span>
                    </div>
                    <div class="goods-handle">
                        <span @click="minusGoodsNum(itemsub)">-</span>
                        <input :value="itemsub.goodsNum" @blur="buyAmountListen(itemsub,$event)"/>
                        <span @click="addGoodsNum(itemsub)">+</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- <div>已选择{{selectGoodCount}}{{editFlag}}</div> -->
        <!-- <div class="shop-list">
            <div class="shop-title">
                <span class="select-icon"><img src="../../images/gouxuan1.png"/></span>
                <img class="shop-icon" src="../../images/images_company-icon.png"/>
                <span class="shopname">天天杂货店铺</span>
                <span class="shoptype">送货上门</span>
                <img class="arrow-right" src="../../images/right_arrow.png"/>
            </div>
            <div class="shop-goods">
                <span class="select-icon"><img src="../../images/gouxuan1.png"/></span>
                <img class="goods-img" src="../../images/goods02.jpg"/>
                <div class="goods-info">
                    <h4>苹果食用植物酵素 500ml</h4>
                    <div class="goods-unit">单位：瓶</div>
                    <div class="price-wrap">
                        <span class="price-num">￥25</span>
                        <span class="specs">/斤</span>
                    </div>
                    <div class="goods-handle">
                        <span>-</span>
                        <input placeholder="2"/>
                        <span>+</span>
                    </div>
                </div>
            </div>
            <div class="shop-goods">
                <span class="select-icon"><img src="../../images/gouxuan1.png"/></span>
                <img class="goods-img" src="../../images/goods02.jpg"/>
                <div class="goods-info">
                    <h4>苹果食用植物酵素 500ml</h4>
                    <div class="goods-unit">单位：瓶</div>
                    <div class="price-wrap">
                        <span class="price-num">￥25</span>
                        <span class="specs">/斤</span>
                    </div>
                    <div class="goods-handle">
                        <span>-</span>
                        <input placeholder="2"/>
                        <span>+</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <ContentNo v-if="carList.length <=0" :contentTips="contentTips"/>
    <div class="fix-bottom"  v-if="carList.length > 0">
        <div class="fix-bottom-l">
            <div class="select-all-wrap">
              <span class="select-icon" @click="selectAllClick"><img :src="allSelect?require('../../images/gouxuan2.png'):require('../../images/gouxuan1.png')"/></span>
              <span class="select-all">全选</span>
            </div>
            <span v-if="!editFlag">合计：</span>
            <span v-if="!editFlag" class="price-num">￥{{carCountPrice}}</span>
        </div>
        <div class="fix-bottom-r" @click="confirmOrder" v-if="!editFlag&&selectGoodCount>0">结算({{selectGoodCount}})</div>
        <div class="fix-bottom-r disabled" v-if="!editFlag&&selectGoodCount<=0">结算</div>
        <div class="fix-bottom-r delete-btn" v-if="editFlag" @click="delCarGoods">删除</div>
    </div>
    <Tabbar :carNumNew="carNumNew"/>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
import Tabbar from '../home/Tabbar.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  components: { Tabbar, ContentNo },
  name: 'ShopCar',
  data () {
    return {
      carList: [],
      selectGoodCount: 0,
      editFlag: false,
      carCountPrice: 0,
      allSelect: false,
      payCarList: [],
      carNumNew: 0,
      contentTips: '您的购物车是空的，赶紧去购物吧！'
    }
  },
  methods: {
    async getCarList () {
      let carNum = 0
      const url = 'v1/cart/getGoodsList.json'
      const param = {
        userId: localStorage.userId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      res.data.list.forEach(item => {
        item.select = false
        item.goodsList.forEach(itemSub => {
          itemSub.select = false
          carNum = carNum + itemSub.goodsNum
        })
      })
      this.carList = res.data.list
      localStorage.carNumNew = carNum
      this.carNumNew = carNum
    },
    // 商品单选
    selectGoodClick (itemsub) {
      let carCountPrice = 0
      this.allSelect = true
      this.selectGoodCount = 0
      itemsub.select = !itemsub.select
      this.carList.forEach(item => {
        item.select = true
        item.goodsList.forEach(itemSub => {
          if (itemSub.goodsId === itemsub.goodsId) {
            itemSub.select = itemsub.select
          }
          if (itemSub.select) {
            this.selectGoodCount++
            carCountPrice += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
          } else {
            item.select = false
            this.allSelect = false
          }
        })
      })
      // this.carCountPrice += carCountPrice
      this.carCountPrice = Math.round(carCountPrice * 100) / 100
    },
    // 店铺选择
    selectShopClick (model) {
      this.selectGoodCount = 0
      let carCountPrice = 0
      this.carCountPrice = 0
      let allSelect = true
      model.select = !model.select
      this.carList.forEach(item => {
        if (item.shopId === model.shopId) {
          item.select = model.select
          item.goodsList.forEach(itemSub => {
            itemSub.select = item.select
          })
        }
        item.goodsList.forEach(itemSub => {
          if (itemSub.select) {
            this.selectGoodCount++
            carCountPrice += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
            // this.carCountPrice = carCountPrice
            this.carCountPrice = Math.round(carCountPrice * 100) / 100
          } else {
            allSelect = false
          }
        })
      })
      this.allSelect = allSelect
    },
    // 全选
    selectAllClick () {
      this.selectGoodCount = 0
      this.carCountPrice = 0
      let carCountPrice = 0
      let allSelect = false
      this.carList.forEach((item, index) => {
        if (this.allSelect) {
          item.select = false
        } else {
          item.select = true
        }
        item.goodsList.forEach(itemSub => {
          itemSub.select = item.select
          if (itemSub.select) {
            this.selectGoodCount++
            allSelect = true
            carCountPrice += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
            // this.carCountPrice = carCountPrice
            this.carCountPrice = Math.round(carCountPrice * 100) / 100
          }
        })
      })
      this.allSelect = allSelect
    },
    editGoods () {
      this.editFlag = !this.editFlag
    },
    // 删除商品
    delCarGoods () {
      let carIds = ''
      this.carList.forEach(item => {
        item.goodsList.forEach(itemSub => {
          if (itemSub.select) {
            carIds = carIds + itemSub.id + ','
          }
        })
      })
      if (carIds) {
        carIds = carIds.substring(0, carIds.length - 1)
        this.$dialog.confirm({
          title: '温馨提示',
          message: '是否删除所选商品'
        }).then(() => {
          // confirm 删除商品
          this.deleteGoods(carIds)
        })
      } else {
        this.$toast('请选择商品')
        return false
      }
    },
    async deleteGoods (cartIds) {
      const url = 'v1/cart/removeAll.json'
      const param = {
        userId: localStorage.userId,
        cartIds: cartIds
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.getCarList()
    },
    // 增加商品
    addGoodsNum (model) {
      model.goodsNum++
      this.addCar(model)
    },
    // 加入购物车
    async addCar (model) {
      var url = 'v1/cart/saveCart.json'
      const goodsId = model.goodsId
      const goodsNum = model.goodsNum
      let carCountPrice = 0
      var param = {
        shopId: model.shopId,
        userId: localStorage.userId,
        goodsId: goodsId,
        number: goodsNum,
        cartId: model.id
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      localStorage.carNumNew = res.data.cartNum
      this.carNumNew = res.data.cartNum
      this.carList.forEach(item => {
        item.goodsList.forEach((itemSub, indexSub) => {
          if (itemSub.select) {
            carCountPrice += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
            // this.carCountPrice = carCountPrice
            this.carCountPrice = Math.round(carCountPrice * 100) / 100
          }
          if (itemSub.goodsNum === 0) {
            item.goodsList.splice(indexSub, 1)
          }
        })
      })
    },
    // 减少商品
    minusGoodsNum (model) {
      if (model.goodsNum <= 1) {
        this.$dialog.confirm({
          title: '温馨提示',
          message: '是否删除当前商品'
        }).then(() => {
          // confirm 删除商品
          model.goodsNum = 0
          this.addCar(model)
          this.carList.forEach((item, index) => {
            item.goodsList.forEach((itemSub, indexSub) => {
              if (itemSub.id === model.id) {
                item.goodsList.splice(indexSub, 1)
                if (item.goodsList.length === 0) {
                  this.carList.splice(index, 1)
                }
              }
            })
          })
        })
        return
      }
      model.goodsNum--
      this.addCar(model)
    },
    // 输入数量更改
    buyAmountListen (model, e) {
      model.goodsNum = e.target.value
      this.addCar(model)
    },
    // 去结算
    confirmOrder () {
      this.carList.forEach((item, index) => {
        const payCarListItem = {}
        payCarListItem.shopId = item.shopId
        payCarListItem.shopName = item.shopName
        payCarListItem.goodsList = []
        item.goodsList.forEach((itemSub, indexSub) => {
          if (itemSub.select) {
            payCarListItem.goodsList.push(itemSub)
          }
        })
        if (payCarListItem.goodsList.length > 0) {
          this.payCarList.push(payCarListItem)
        }
      })
      localStorage.setItem('payCarList', JSON.stringify(this.payCarList))
      // 优惠券数据清空
      localStorage.removeItem('checkedCouponItem')
      this.$router.push('/confirmOrder')
    }
  },
  created () {
    this.getCarList()
    this.carNumNew = localStorage.carNumNew
  },
  watch: {
    carNumNew () {
      this.carNumNew = localStorage.carNumNew
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-btn-wrap{
    background:#52da5f;
    padding: .15rem .1rem .3rem;
    display: flex;
    justify-content: flex-end;
    color:#fff
}
.shop-wrap{margin-top: -0.2rem; padding-bottom: .9rem;}
.shop-list{
    background:#fff;
    border-radius: .1rem;
    margin: 0 .1rem .1rem;
    padding: .1rem 0 0;
    .shop-title{
        display: flex;
        align-items: center;
        position: relative;
        padding: .05rem 0 .15rem;
        .shop-icon{width:.21rem; height:.19rem; margin: 0 .1rem 0 0}
        .shopname{font-weight: bold;}
        .shoptype{
            margin-left: .1rem;
            padding:.02rem .05rem;
            background:#52da5f;
            border-radius: .08rem;
            font-size: .1rem;
            color:#fff
        }
        .arrow-right{
            width: .07rem;
            height: .12rem;
            position:absolute;
            right: .1rem;
            top: 50%;
            margin-top: -0.1rem
        }
    }
    .select-icon img{
        width: .16rem;
        height: .16rem;
        padding: 0 .1rem;
    }
    .shop-goods{
        display: flex;
        align-items: center;
        position: relative;
        padding:.1rem 0;
        border-bottom: 1px solid #f3f3f3;
        .goods-img{
            width: .8rem;
            height: .8rem;
            margin-right:.1rem;
            border-radius: .05rem;
        }
        .goods-info{
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: space-between;
            line-height: .24rem;
            .price-wrap{
                font-size: .13rem;
                .specs{color:#666}
            }
            .goods-unit{font-size:.13rem; color:#666}
            .goods-handle{
                position: absolute;
                right: .1rem;
                bottom: .1rem;
                display: flex;
                align-items: center;
                input{
                    width:.3rem;
                    height:.25rem;
                    border:none;
                    text-align: center;
                    line-height: .25rem;
                    font-size: .1rem
                }
                input:focus{outline: none;}
                span{
                    display: inline-flex;
                    padding: 0 .05rem;
                    font-size: .2rem;
                    line-height: .25rem;
                }
            }
        }
    }
    .shop-goods:last-of-type{border-bottom:none;}
}
.fix-bottom{
    position: fixed;
    left:0; right:0; bottom:.52rem;
    height: .4rem;
    background:#fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fix-bottom-l{
        display: flex;
        padding: 0 .1rem;
        align-items: center;
        .select-all-wrap{
          display: flex;
          align-items: center;
        }
        img{
            width: .16rem;
            height: .16rem;
            margin-right: .05rem
        }
        span{font-size: .12rem;}
        .select-all{
          font-size: .14rem;
          margin-right:.1rem;
        }
    }
    .fix-bottom-r{
        height:100%;
        background:#39A94A;
        padding: 0 .3rem;
        color:#fff;
        line-height: .4rem;
    }
    .disabled{
        background:gray
    }
    .delete-btn{
        background: #ff371e;
        color:#fff
    }
}
.price-num{color:#ff371e}
</style>
